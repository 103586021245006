import '../sass/main.scss';

function onScroll(f, time) {
  let didScroll = false;
  window.addEventListener('scroll', function () {
    didScroll = true;
  });

  setInterval(function () {
    if (didScroll) {
      didScroll = false;
      f();
    }
  }, time);
}

function getScrollTop() {
  if (typeof pageYOffset != 'undefined') {
    return pageYOffset;
  } else {
    var B = document.body; //IE 'quirks'
    var D = document.documentElement; //IE with doctype
    D = D.clientHeight ? D : B;
    return D.scrollTop;
  }
}

function smoothstep(start, end, point) {
  if (point <= start) {
    return 0;
  }
  if (point >= end) {
    return 1;
  }
  const x = (point - start) / (end - start);
  return x * x * (3 - 2 * x);
}

function throttle(fn, wait) {
  var time = Date.now();
  return function () {
    if ((time + wait - Date.now()) < 0) {
      fn();
      time = Date.now();
    }
  }
}

function fadeOut(el, ms) {
  if (ms) {
    el.style.transition = 'opacity ' + ms + 'ms';
  }
  el.style.opacity = '0';
  setTimeout(function () {
    el.style.display = 'none';
  }, ms)
}

function fadeIn(el, ms) {
  el.style.display = '';

  if (ms && el.style.transition == '') {
    el.style.transition = 'opacity ' + ms + 'ms';
  }
  el.style.opacity = 1;
}

function smoothscrollJS() {
  const duration = 500;
  const step = 10;
  const offset = 0;
  const anchorLinks = document.querySelectorAll('a[href^="#"]:not([href="#"])');

  for (let i = 0; i < anchorLinks.length; i++) {
    anchorLinks[i].addEventListener('click', function (e) {
      e.preventDefault();
      const target = document.getElementById(
        this.getAttribute('href').substring(1)
      );
      if (target) {
        smoothScroll(target);
      }
    });
  }

  function smoothScroll(target) {
    const start_time = Date.now();
    const end_time = start_time + duration;

    const start_top = getScrollTop();
    const distance = target.getBoundingClientRect().top - offset;

    const timer = setInterval(function () {
      const now = Date.now();
      // scrollTop at a given point in time
      const point = smoothstep(start_time, end_time, now);
      const scrollTop = Math.round(start_top + distance * point);
      window.scrollTo(0, scrollTop);
      // clear the timer when finished
      if (now > end_time) {
        clearInterval(timer);
      }
    }, step);
  };
}

function scrolltopJS() {
  const scrolltop = document.getElementById('scrolltop');
  // fadein/fadeout on scroll
  if (scrolltop) {
    let scrolled = false;

    window.addEventListener('scroll', throttle(function () {
      const scrolled = getScrollTop();
      if (scrolled >= 900 && !scrolltop.classList.contains('revealed')) {
        scrolltop.classList.add('revealed');
        fadeIn(scrolltop, 300);
      } else if (scrolled < 900 && scrolltop.classList.contains('revealed')) {
        scrolltop.classList.remove('revealed');
        fadeOut(scrolltop, 300);
      }
    }, 100));
  }
  // scroll to top
  scrolltop.addEventListener('click', function (e) {
    e.preventDefault();
    const duration = 500;
    const step = 10;
    const start_time = Date.now();
    const end_time = start_time + duration;

    const start_top = getScrollTop();
    const distance = -start_top;

    const timer = setInterval(function () {
      const now = Date.now();
      // scrollTop at a given point in time
      const point = smoothstep(start_time, end_time, now);
      const scrollTop = Math.round(start_top + (distance * point));
      window.scrollTo(0, scrollTop);
      // clear the timer when finished
      if (now > end_time) {
        clearInterval(timer);
      }
    }, step);

  });
}

function initAnimationsOnScroll() {
  const animStack = document.getElementsByClassName('anim');
  const animStaggerStack = document.getElementsByClassName('anim-stagger');

  function animateVisible() {
    const pageTop = getScrollTop();
    const pageBottom = pageTop + window.innerHeight;

    for (let i = 0; i < animStack.length; i++) {
      const elm = animStack[i];
      // relative to viewport!
      const elementTop = animStack[i].getBoundingClientRect().top;
      const elementBottom = elementTop + animStack[i].getBoundingClientRect().height;

      if (elementTop <= window.innerHeight - 50 && elementBottom >= -50) {
        if (!elm.classList.contains('go-go-go')) {
          elm.classList.add('go-go-go');
          // force reflow for special child SAFARI
          const newone = elm.cloneNode(true);
          elm.parentNode.replaceChild(newone, elm);
        }
      }
    }

    // stagger elements
    const delay = 150;
    for (let i = 0; i < animStaggerStack.length; i++) {
      const elementTop = animStaggerStack[i].getBoundingClientRect().top;
      const elementBottom = elementTop + animStaggerStack[i].getBoundingClientRect().height;
      if (elementTop <= window.innerHeight - 50 && elementBottom >= -50) {
        animStaggerStack[i].style.animationDelay = `${i*delay}ms`;
        animStaggerStack[i].classList.add('go-go-go');
      }
    };

  };

  onScroll(animateVisible, 100);
  window.scroll();
}

function toggleBodyLock(){
  const body = document.querySelector('body');
  body.classList.toggle('scrollLock');
}

function initNav() {
  const navList = document.querySelector('#gnav .navlist');
  const navToggle = document.querySelector('#gnav .nav-toggle');


  navToggle.addEventListener('click', function(e) {
    e.stopPropagation();
    this.classList.toggle('active');
    navList.classList.toggle('open');
    toggleBodyLock();
  });

  const navLinks = Array.prototype.slice.call(document.querySelectorAll('#gnav .navlist a'));

  for (let i = 0; i < navLinks.length; i++) {
    const link = navLinks[i];
    link.addEventListener('click', function (e) {
      e.stopPropagation();
      navToggle.classList.remove('active');
      navList.classList.remove('open');
      document.querySelector('body').classList.remove('bodyLock');
    });
  };

  // closing on clicks and Esc key
  window.addEventListener('click', function (e) {
    if (navList.classList.contains('open')) {
      navToggle.classList.remove('active');
      navList.classList.remove('open');
      toggleBodyLock()
    }
  });
  window.addEventListener('keyup', function (e) {
    if (e.keyCode == 27 && navList.classList.contains('open')) {
      navToggle.classList.remove('active');
      navList.classList.remove('open');
      toggleBodyLock()
    }
  });
}

if (document.readyState !== 'loading') {
  initNav();
  smoothscrollJS();
  scrolltopJS();
  initAnimationsOnScroll();
} else {
  document.addEventListener('DOMContentLoaded', initNav);
  document.addEventListener('DOMContentLoaded', smoothscrollJS);
  document.addEventListener('DOMContentLoaded', scrolltopJS);
  document.addEventListener('DOMContentLoaded', initAnimationsOnScroll);
}